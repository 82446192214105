import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";

import "./assets/index.css";
import permissions from "./permissions";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import { vue3Debounce } from "vue-debounce";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import * as Sentry from "@sentry/vue";
import VueApexCharts from "vue3-apexcharts";
import "v-network-graph/lib/style.css";
import VNetworkGraph from "v-network-graph";

const app = createApp(App);

app.use(permissions);
app.use(createPinia());
app.use(router);
app.use(Toast);
app.use(VueApexCharts);
app.use(VNetworkGraph);

Sentry.init({
	app,
	environment: import.meta.env.VITE_ENVIRONMENT,
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ["https://staging.api.moos.nu", "https://staging.app.moos.nu", "https://api.moos.nu", "https://app.moos.nu"],
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	enabled: import.meta.env.VITE_SENTRY_ENABLED === "true",
});

dayjs.extend(relativeTime);
app.directive("debounce", vue3Debounce({ lock: true }));

app.mount("#app");

import axios, { AxiosError, type AxiosRequestConfig, type AxiosResponse } from "axios";
import type { BaseModel } from "./models";
import { useUserStore } from "./stores/user";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

//@ts-ignore
window.Pusher = Pusher;

const api = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
	withCredentials: true,
});

api.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error?.response?.status === 401 || error?.response?.status === 419) {
			localStorage.clear();
			console.error(error);
			if (window.location.pathname != "/login") {
				window.location.assign(`/login?redirect=${window.location.pathname}`);
			} else {
				window.location.assign(`/login`);
			}
		}
		throw error;
	},
);

export type ApiLinks = {
	first: string;
	last: string;
	prev: string;
	next: string;
};

export type ApiMeta = {
	current_page: number;
	from: number;
	last_page: number;
	per_page: number;
	to: number;
	total: number;
};

type ApiResponse<M> = {
	data: M;
	meta: ApiMeta;
	links: ApiLinks;
};

export type WrappedModel<M> = {
	data: M;
	meta: {
		current_page: number;
		from: number;
		last_page: number;
		per_page: number;
		to: number;
		total: number;
	};
	links: ApiLinks;
};

export interface FindQuery {
	filters: {
		type?: "or" | "and";
		field: string;
		operator: "<" | "<=" | ">" | ">=" | "=" | "!=" | "like" | "not like" | "ilike" | "not ilike" | "in" | "not in" | "all in" | "any in";
		value: any;
	}[];
	search: {
		value: string;
		case_sensitive?: boolean;
	};
	sort: {
		field: string;
		direction: "desc" | "asc";
	}[];
}

export default class API {
	private static hydrate<T extends Record<string, any>>(response: ApiResponse<T>): WrappedModel<T> {
		let hydrated_data: Record<string, any> = {};
		const startTime = performance.now();

		const hydrateObject = (obj: Record<string, any>) => {
			if (!obj) return obj;
			const keys = Object.keys(obj);

			keys.forEach((key) => {
				if (key == "attributes") {
					obj = Object.assign(obj[key], obj);
					delete obj[key];
				} else if (key == "relationships") {
					Object.keys(obj[key]).forEach((sub) => {
						if (Array.isArray(obj[key][sub])) {
							obj[sub] = [];
							Object.keys(obj[key][sub]).forEach((arr) => {
								obj[sub][arr] = hydrateObject(obj[key][sub][arr]);
							});
						} else {
							obj[sub] = hydrateObject(obj[key][sub]);
						}
					});
					delete obj[key];
				}
			});

			return obj;
		};

		if (Array.isArray(response.data)) {
			const keys = Object.keys(response.data);
			hydrated_data = [];
			keys.forEach((key) => {
				hydrated_data[key] = hydrateObject(response.data[key] as any);
			});
		} else {
			hydrated_data = hydrateObject(response.data);
		}

		const endTime = performance.now();

		// console.info(`Hydration took ${endTime - startTime} milliseconds`);

		return {
			...response,
			meta: response.meta,
			links: response.links,
			data: hydrated_data as T,
		};
	}

	static async get<M extends Record<string, any>>(url: string, config?: AxiosRequestConfig<any>): Promise<WrappedModel<M>> {
		return api
			.get<ApiResponse<M>>(url, {
				...config,
				params: {
					client_id: useUserStore().selectedClient,
				},
			})
			.then((x) => this.hydrate(x.data));
	}

	static async store<M extends Record<string, any>>(url: string, model: M, config?: AxiosRequestConfig<any>): Promise<WrappedModel<M>> {
		return api
			.post<ApiResponse<M>>(url, model, {
				...config,
				params: {
					client_id: useUserStore().selectedClient,
				},
			})
			.then((x) => this.hydrate(x.data));
	}

	static async find<M extends Record<string, any>>(
		url: string,
		query: Partial<FindQuery>,
		config?: AxiosRequestConfig<any>,
	): Promise<WrappedModel<M>> {
		return api
			.post<ApiResponse<M>>(url, query, {
				...config,
				params: {
					client_id: useUserStore().selectedClient,
				},
			})
			.then((x) => this.hydrate(x.data));
	}

	static async post<M extends Record<string, any>>(
		url: string,
		data: Record<string, any>,
		config?: AxiosRequestConfig<any>,
	): Promise<WrappedModel<M>> {
		return api
			.post<ApiResponse<M>>(url, data, {
				...config,
				params: {
					client_id: useUserStore().selectedClient,
				},
			})
			.then((x) => this.hydrate(x.data));
	}

	static async patch<M extends Record<string, any>>(
		url: string,
		model: Partial<M>,
		config?: AxiosRequestConfig<any>,
	): Promise<WrappedModel<M>> {
		return api
			.patch<ApiResponse<M>>(url, model, {
				...config,
				params: {
					client_id: useUserStore().selectedClient,
				},
			})
			.then((x) => this.hydrate(x.data));
	}

	static async delete<M extends Record<string, any>>(url: string, config?: AxiosRequestConfig<any>): Promise<WrappedModel<M>> {
		return api
			.delete<ApiResponse<M>>(url, {
				...config,
				params: {
					client_id: useUserStore().selectedClient,
				},
			})
			.then((x) => this.hydrate(x.data));
	}

	static async postRaw<T = any>(url: string, data: unknown, config?: AxiosRequestConfig<any>): Promise<AxiosResponse<T>> {
		return api.post<T>(url, data, {
			...config,
			params: {
				client_id: useUserStore().selectedClient,
			},
		});
	}

	static raw() {
		return api;
	}
}

export function ResolveError(error: AxiosError): string {
	// @ts-ignore
	if (error.response?.data.message) {
		// @ts-ignore
		return error.response?.data.message;
	}
	return error.message;
}

/**
 * Soketi configuration
 */
export const socket = new Echo({
	broadcaster: "pusher",
	key: import.meta.env.VITE_PUSHER_APP_KEY,
	wsHost: import.meta.env.VITE_PUSHER_HOST,
	wsPort: import.meta.env.VITE_PUSHER_PORT,
	wssPort: import.meta.env.VITE_PUSHER_PORT,
	forceTLS: false,
	encrypted: true,
	disableStats: true,
	enabledTransports: ["ws", "wss"],
	cluster: "",
	authEndpoint: import.meta.env.VITE_API_URL + "/broadcasting/auth",
});
